import type { SxProps } from '@mui/material';
import { Box } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

export const MiddleFullPageLayout: FC<PropsWithChildren<{ sx: SxProps }>> = ({
  children,
  sx,
}) => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflow: 'auto',
    }}
  >
    <Box
      sx={{
        alignItems: 'center',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '100%',
        mx: 'auto',
        p: 3,
        ...sx,
      }}
    >
      {children}
    </Box>
  </Box>
);

export const MiddleLayout: FC<PropsWithChildren> = ({ children }) => (
  <Box
    sx={{
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflow: 'auto',
    }}
  >
    <Box
      sx={{
        alignItems: 'center',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '100%',
        mx: 'auto',
        p: 3,
      }}
    >
      {children}
    </Box>
  </Box>
);
