import type { ButtonProps, SxProps } from '@mui/material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  createTheme,
} from '@mui/material';
import type { FC } from 'react';
import { MiddleFullPageLayout } from '../../components/page-layout/middle.js';
import { LogoOnPrimary } from '../../img/logo-on-primary.js';
import { Auth0Icon } from './icons/auth-zero.js';
import { FacebookIcon } from './icons/facebook.js';
import { GoogleIcon } from './icons/google.jsx';

interface Props {
  onSignInClick: (provider: string) => void;
}

const Footer: FC = () => (
  <Typography
    sx={{
      m: 1,
      fontSize: 'small',
      color: (theme) => theme.palette.text.disabled,
    }}
  >
    Copyright &copy;
  </Typography>
);

const whiteButton = (): SxProps => {
  const color = createTheme().palette.augmentColor({
    color: { main: '#f0f0f0' },
  });
  return {
    backgroundColor: color.main,
    color: color.contrastText,
    '&:hover': {
      backgroundColor: color.dark,
    },
    '&:disabled': {
      backgroundColor: color.light,
    },
  };
};

const SignInButton: FC<ButtonProps> = (props) => (
  <Button
    {...props}
    variant="contained"
    fullWidth
    size="large"
    sx={{ justifyContent: 'start', ...props.sx }}
  />
);

export const NotAuthenticatedView: FC<Props> = ({ onSignInClick }) => (
  <Box
    sx={{
      height: '100%',
      width: '100%',
      backgroundImage: 'url("/assets/contours.svg")',
      backgroundSize: 'cover',
    }}
  >
    <MiddleFullPageLayout sx={{ maxWidth: 400 }}>
      <Card elevation={6} sx={{ alignSelf: 'stretch' }}>
        <CardContent
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.primary.contrastText,
            py: 2,
          }}
        >
          <Typography variant="h2" sx={{ textAlign: 'center' }}>
            <LogoOnPrimary sx={{ height: 32, mb: 1 }} />
          </Typography>
          <Typography
            variant="body2"
            sx={{ textAlign: 'center', opacity: 0.8 }}
          >
            Compare your orienteering routes
          </Typography>
        </CardContent>
        <CardContent>
          <Stack spacing={3}>
            <Box>Sign in or join us, it's free:</Box>
            <SignInButton
              startIcon={<GoogleIcon height={24} width={24} />}
              sx={whiteButton()}
              onClick={() => onSignInClick('google')}
            >
              Sign In With Google
            </SignInButton>
            <SignInButton
              startIcon={<FacebookIcon height={24} width={24} />}
              sx={whiteButton()}
              onClick={() => onSignInClick('facebook')}
            >
              Sign In With Facebook
            </SignInButton>
            <SignInButton
              startIcon={<Auth0Icon height={24} width={24} />}
              sx={whiteButton()}
              onClick={() => onSignInClick('auth0')}
            >
              Sign In With Email
            </SignInButton>
          </Stack>
        </CardContent>
      </Card>
      <Footer />
    </MiddleFullPageLayout>
  </Box>
);
