import { captureException } from '@sentry/react';
import type { FC } from 'react';
import { useAuth } from '../../auth/auth-provider.js';
import { NotAuthenticatedView } from './view.js';

const Controller: FC = () => {
  const auth = useAuth();

  return NotAuthenticatedView({
    onSignInClick: (provider) => {
      auth.signIn(provider).catch(captureException);
    },
  });
};

export default Controller;
