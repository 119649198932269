import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';
import type { FC } from 'react';

export const Auth0Icon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 512 512" {...props}>
    <path
      fill="#eb5424"
      d="M358.1,378.8 319.6,260 420.5,186.9H295.7l-38.6,-118.7-0.01,-0.03h124.8l38.6,118.7v-0.003l0.03,-0.02c22.4,68.8-0.7,147-62.4,192zm-201.9,0-0.036,0.03L257.13,452.2 358.09,378.84 257.17,305.51ZM93.85,186.85c-23.57,72.57 3.79,149.46 62.36,192l0.01,-0.036L194.77,260.17 93.89,186.87H218.6L257.15,68.2 257.2,68.2H132.4Z"
    />
  </SvgIcon>
);
